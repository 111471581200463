/** css Reset **/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/** FIM - css Reset **/

* {
	box-sizing: border-box;
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

h1 {
	font-size: 3em;
}

h2 {
	font-size: 2.7em;
}

h3 {
	font-size: 2.4em;
}

h4 {
	font-size: 2.1em;
}

h5 {
	font-size: 1.8em;
}

h6 {
	font-size: 1.5em;
}

div.container {
  min-height: 100vh;
  height: 100vh;
}

.container .header {
	max-height: 12%;
	height: 12%;
	padding: 15px 0;
	display: flex;
	justify-content: center;
}

.container .header .logo {
	width: 25%;
	padding-left: 2%;
}

.container .header .title {
	width: 75%;
}

.container .header h1 {
	font-size: 3em;
	font-weight: 600;
	color: #3f51b4;
}

.container .principal {
	max-height: 80%;
	height: 80%;
	margin: 0 2%;
	border-radius: 15px;
	background-color: #D1D7DE;
}

.container .liveness-face {
	max-height: 80%;
	height: 80%;
	margin: 0 2%;
}

.container .footer {
	max-height: 8%;
	height: 8%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container .footer p {
	text-align: center;
}

#compareImagesForm {
  padding: 40px;
  width: 100%;
	height: 100%;
}

#compareImagesForm header {
	max-height: 5%;
	height: 5%;
}

#compareImagesForm section {
	max-height: 85%;
	height: 85%;
}

#compareImagesForm section .tab .tab-header {
	max-height: 10%;
	height: 10%;
}

#compareImagesForm section .tab .tab-section {
	max-height: 90%;
	height: 90%;
	display: flex;
}

#compareImagesForm section .tab .tab-section .tab-article-webcam {
	width: 45%;
}

#compareImagesForm section .tab .tab-section .tab-article-webcam #largeVideoContainer, #compareImagesForm section .tab .tab-section .tab-article-webcam #largeVideoContainer #largeVideoElementsContainer, #compareImagesForm section .tab .tab-section .tab-article-webcam #largeVideoContainer #largeVideoElementsContainer #largeVideoWrapper, #compareImagesForm section .tab .tab-section .tab-article-webcam #largeVideoContainer #largeVideoElementsContainer #largeVideoWrapper #largeVideo {
	width: 100%;
	height: 100%;
}

#compareImagesForm section .tab .tab-header {
	display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#compareImagesForm section .tab .tab-section .tab-article-capture {
	width: 10%;
	display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#compareImagesForm section .tab .tab-section .tab-article-preview {
	width: 45%;
	padding-left: 1.6%;
}

#compareImagesForm section .tab .tab-section .tab-article-insert-file {
	width: 50%;
	padding: 1.6%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#compareImagesForm section .tab .tab-section .tab-article-file-galery {
	width: 50%;
	padding: 1.6%;
}

#compareImagesForm section .tab .tab-section .tab-section-review {
	display: flex;
}

.tab-article-review header {
	height: 15%!important;
	max-height: 15%!important;
}

#compareImagesForm section .tab .tab-section .tab-article-review {
	padding: 1.6%;
}

.tab-article-review-webcam-image {
	width: 37.5%;
}

.tab-article-review-match {
	width: 25%;
}

.tab-article-review-match .icon {
	height: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tab-article-review-match .response {
	height: 70%;
}

.tab-article-review-gallery-image {
	width: 37.5%;
}

.image-preview-content {
	position: relative;
}

.image-preview-content button {
	position: absolute;
	right: 0;
	top: 0;
	color: #000;
	background-color: rgba(255, 255, 255, .6);
}

#compareImagesForm footer {
	max-height: 10%;
	height: 10%;
	display: flex;
}

#compareImagesForm footer .blank {
	width: 25%;
}

#compareImagesForm footer .steps {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

#compareImagesForm footer .buttons {
	width: 25%;
	display: flex;
	align-content: flex-start;
	justify-content: flex-end;
}

#fileUploadButtonLabel {
	display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(63, 81, 180);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

button {
	display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(63, 81, 180);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

#compareImagesForm footer button:hover {
  opacity: 0.8;
}

#compareImagesForm footer #prevBtn {
	background-color: #959595;
}

.success-button {
	background-color: #3f51b4;
}

/* Hide all steps by default: */
#compareImagesForm .tab {
  display: none;
	width: 100%;
	height: 95%;
	border-radius: 15px;
	padding: 2%;
	background-color: #FFF;
}

/* Make circles that indicate the steps of the form: */
#compareImagesForm .step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
#compareImagesForm .step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
#compareImagesForm .step.finish {
  background-color: #3f51b4;
}

/* SNACKBAR */

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  right: 40%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

/* */

.success {
	background-color: #43a047!important;
}

.error {
	background-color: #e53935!important;
}

.info {
	background-color: #1e88e5!important;
}

/* */

#overlay {
	z-index: 999999999;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: none;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
	z-index: 999999999;
	position: absolute; 
	left: 48%;
	top: 48%;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.material-symbols-outlined {
	font-variation-settings:
	'FILL' 1,
	'wght' 700,
	'GRAD' 0,
	'opsz' 48
}

#gallery .thumbnail{
width:450px;
height: 450px;
margin:2px;
}
#gallery .thumbnail img{
width:450px;
height: 450px;
}
.LockOn {
display: block;
visibility: visible;
position: absolute;
z-index: 999;
top: 0px;
left: 0px;
width: 105%;
height: 205%;
background-color:white;
vertical-align:bottom;
padding-top: 20%; 
filter: alpha(opacity=75); 
opacity: 0.75; 
font-size:large;
color:blue;
font-style:italic;
font-weight:400;
background-image: url("../loading.gif");
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.container .header {
		flex-direction: column;
		max-height: none;
		height: auto;
	}

	.container .header .logo {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.container .header .title {
		width: 100%;
	}

	.container .header .title h1 {
		text-align: center;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.container .header {
		flex-direction: column;
		max-height: none;
		height: auto;
	}

	.container .header .logo {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.container .header .title {
		width: 100%;
	}

	.container .header .title h1 {
		text-align: center;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.container .header {
		flex-direction: row;
	}

	.container .header .logo {
		width: 25%;
		padding-left: 2%;
		display: block;
	}
	
	.container .header .title {
		width: 75%;
		display: block;
	}

	.container .header .title h1 {
		text-align: left;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}